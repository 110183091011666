import { useContext, useEffect, useState } from 'react';
import {Drawer, Typography, Container, Box, Button, Icon, IconButton, Menu, MenuItem} from '@mui/material';
//import Link from '@mui/material/Link';
import {BrowserRouter as Router, Redirect, Route, Routes, NavLink, Link, useMatch, useResolvedPath, resolvePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import {ExitToApp, AccountCircle, Map, List, Search, NotificationImportant, Close} from '@mui/icons-material';
import {BsDatabase} from "react-icons/bs"
import { useTheme } from '@mui/material/styles';
import { UserContext } from "../services/UserContext"
import API from '../services/API';
import Plan from './Plan';
import Concessions from './Concessions';
import Concession from './Concession';
import PopupAjoutFichier from './PopupAjoutFichier';
import Creation_Concession from './Creation_Concession';
import Recherche from './Recherche';
import Input from '../components/Input';
import Alertes from './Alertes';
import Tempo from './Tempo';
import {useSnackbar} from 'notistack';


export default function Accueil(){
    const [userContext, setUserContext] = useContext(UserContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputRecherche, setInputRecherche] = useState("");
    const [premierAffichage, setPremierAffichage] = useState(true);

    let navigate = useNavigate();
    const theme = useTheme()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const styleIcons = {
        position: "absolute",
        left: 20,
        top: "50%",
        transform: "translateY(-50%)",
        height: 24,
        width: 24
    }
    const styleLinkTexts ={
        marginLeft: 20,
        display: "block"
    }

    useEffect(() => {
        if(!premierAffichage) return
        setPremierAffichage(false)

        API("GET","Alerte",{},{statut:["Non traitée","En traitement"]})
        .then(res=>{
            console.log('Herecoucou');
            let data = res.json
            let toAlert = data.length+" alerte(s) en cours dont :\n"
            toAlert += "- "+data.filter(l=>l.statut=="Non traitée").length+" non traitée(s)\n"
            toAlert += "- "+data.filter(l=>new Date(l.date) > new Date(new Date().setDate(new Date().getDate() - 30))).length+" créées dans les 30 derniers jours"
            enqueueSnackbar(toAlert,
                {variant: "warning",
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)} size="small" sx={{position: "absolute",top: 0,right: 0,color: "white", }}>
                    <Close fontSize="small" />
                    </IconButton>
                ),
                style: { whiteSpace: 'pre-line', fontSize:22}, 
                preventDuplicate:true, 
                autoHideDuration:10000
            })
        })
    }, []);

    /*useEffect(()=>{
        if(!!userContext.prenom) return
        API("GET","utilisateur/me",{},{},"application/json",{credentials:"include"}, userContext.token)
        .then(res=>{
            if (res.ok) {
                setUserContext(currVal => ({...currVal, ...res.json[0] }))
            } else {
                if (res.status === 401) {
                  // Edge case: when the token has expired.
                  // This could happen if the refreshToken calls have failed due to network error or
                  // User has had the tab open from previous day and tries to click on the Fetch button
                  window.location.reload()
                } 
              }
        })
    },[userContext])*/

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleKeyDown = (e)=>{
        if(e.key === "Enter"){
            rechercher()
        }
    }
    const rechercher = ()=>{
        if(inputRecherche.length>0){
            navigate("/recherche/"+inputRecherche)
            API("POST","log",{Utilisateur_id: userContext.id, action:"Accès à la page résultat de recherche"})
        }
    }

    const handleLogout=()=>{
        API("GET","utilisateur/logout",{},{},"application/json",{credentials:"include"}, userContext.token)
        .then(res=>{
            console.log("logout",res)
            setUserContext(currVal => ({ ...currVal, token: null }))

        })
    }

    const drawerWidth = 180

    return(
        <Box sx={{ display: 'flex' }}>
            <Box component="nav" sx={{ width: drawerWidth , flexShrink: 0  }}>
                <Drawer variant="permanent" anchor="left" sx={{width: 180, '& .MuiDrawer-paper': {width: 180,boxSizing: 'border-box', bgcolor:"primary.main"}}} >
                    <Typography sx={{color:"white", textAlign:"center",mb:3, fontSize:25}}>Gestion du cimetière de Bretonvillers</Typography>
                    
                    <Box sx={{position:"relative", mx:"5px", my:3}}>
                        <Input name="Recherche" onChange={(v)=>setInputRecherche(v)} otherProps={{sx:{"& .MuiInputBase-root":{color:"white"}, "& .MuiFormLabel-root":{color:theme.palette.gris.contour}, "& .MuiOutlinedInput-notchedOutline":{borderColor:"white !important"}}, size:"small", onKeyDown:handleKeyDown}} sx={{}} />
                        <IconButton onClick={rechercher} sx={{position:"absolute", right:0, color:"white"}}><Search/></IconButton>
                    </Box>
                    <CustomLink to="plan"><Map/><span style={{verticalAlign:"super"}}>Plan cimetière</span></CustomLink>
                    <CustomLink to="concessions"><List/><span style={{verticalAlign:"super"}}>Concessions</span></CustomLink>
                    <CustomLink to="alertes"><NotificationImportant/><span style={{verticalAlign:"super"}}>Alertes</span></CustomLink>

                    <Box sx={{mt:"auto", pt:"50px"}}>
                        <Typography sx={{color:"gris.contour", fontSize:"0.85rem", textAlign:"center"}}>Bonjour {userContext.prenom}</Typography>
                        <IconButton sx={{color:"gris.contour", display:"block", margin:"auto"}} onClick={handleMenu}><AccountCircle/></IconButton>
                        <Menu anchorEl={anchorEl} anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} keepMounted
                            transformOrigin={{vertical: 'top',horizontal: 'center',}} open={Boolean(anchorEl)} onClose={handleClose}>
                            {/*<MenuItem onClick={handleClose}>Mon profil</MenuItem>*/}
                            <MenuItem onClick={()=>navigate("/admin/utilisateur/"+userContext.id)}><AccountCircle/>Profil</MenuItem>
                            <MenuItem onClick={handleLogout}><ExitToApp/>Déconnexion</MenuItem>
                        </Menu>
                        {/*<Button sx={{display:"block",color:"white", fontSize:"10px",mx:"auto", "&:hover":{textDecoration:"underline"}}} onClick={handleLogout}><ExitToApp style={{verticalAlign:"sub"}}/><span style={{verticalAlign:"super"}}>Déconnexion</span></Button>*/}
                    </Box>
                </Drawer>
            </Box>
            <Box component="main" maxWidth="lg" sx={{ flexGrow: 1, pt:2, width: `calc(100% - ${drawerWidth}px)`, marginX:"auto", minWidth:"900px", px:2}}>
                <Routes>
                    <Route path="/" element={<Plan/>}/>
                    <Route path="plan" element={<Plan/>}/>
                    <Route path="concessions" element={<Concessions/>}/>
                    <Route path="concession/creer/" element={<Creation_Concession/>}/>
                    <Route path="concession/creer/:Emplacement_nom" element={<Creation_Concession/>}/>
                    <Route path="concession/:numero" element={<Concession/>}/>
                    <Route path="recherche/:input" element={<Recherche/>}/>
                    <Route path="alertes" element={<Alertes/>}/>
                    <Route path="tempo/:pageSuivante" element={<Tempo/>}/>
                </Routes>
            </Box>
        </Box>
    )
}


function CustomLink({ children, to, ...props }) {
    const [userContext, setUserContext] = useContext(UserContext)
    const theme = useTheme()
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    const handleClick = ()=>{
        API("POST","log",{Utilisateur_id:userContext.id, action:"Accès à la page "+to})
    }
  
    return (
        <Link
          style={{ color: match ? "white" : theme.palette.gris.contour, textDecoration:"none", textAlign:"center", marginTop:20 }}
          to={to}
          onClick={handleClick}
          {...props}
        >
          {children}
        </Link>
    );
  }

import { Button, Alert } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, resolvePath } from "react-router-dom";
import Input from "../components/Input"
import API from "../services/API";
import { UserContext } from "../services/UserContext"



export default function Login({}){
    const [login, setLogin] = useState({username:undefined, password:undefined})
    const [isOk, setIsOk] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState("")
    const [userContext, setUserContext] = useContext(UserContext)

    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/admin";

    const handleChange = (key, value)=>{
        setLogin(currVal=>({...currVal, [key]:value}))
    }

    useEffect(()=>{
        setIsOk(Object.values(login).filter(l=>l===undefined).length===0)
    },[login])

    const handleLogin=(e)=>{
        e.preventDefault()
        setIsSubmitting(true)   
        setError("")
        API("POST","utilisateur/login",login,{},"application/json",{credentials:"include"})
        .then(res=>{
            setIsSubmitting(false)
            if (!res.ok) {
                if(res.status===401){
                    setError("Identifiant ou mot de passe incorrect")
                }else{
                    setError("Une erreur est survenue. Veuillez réessayer")
                }
            }else{
                setUserContext(res.json)
                navigate(from, { replace: true });
                API("POST","log",{Utilisateur_id:res.json.id, action:"Login"})
            }
        })
    }

    return (
        <Box sx={{backgroundImage:'url("photo_cimetiere.jpeg")', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
            <Box sx={{ height:"100vh", margin:"auto",display: "flex",flexDirection: "column",alignItems: "center", "& > *":{m:"5px !important"}}}>
                <Box sx={{flex:1, fontSize: "28px", width: "60%", textAlign: "center", minWidth: "500px", paddingTop: "20px"}}>
                    Bienvenue sur  l'application de gestion  du cimetière de Bretonvillers
                </Box>
                <Box sx={{width:300, p:3, border:"1px solid gray", textAlign:"center", backgroundColor: "rgba(255,255,255,0.5)", borderRadius: "10px", "& > *":{m:"5px !important"}}}>
                    {error!=="" && <Alert severity="error">{error}</Alert>}
                    <form onSubmit={handleLogin}>
                        <Input name="Identifiant" required={true} onChange={(val)=>handleChange("username",val)}/>
                        <Input name="Mot de passe" required={true} onChange={(val)=>handleChange("password",val)} otherProps={{type:"password"}} style={{marginTop:10, marginBottom:10}}/>
                        <Button type="submit" variant="contained"/* onClick={handleLogin}*/ disabled={!isOk || isSubmitting}>{isSubmitting?"Identification...":"S'identifier"}</Button>
                    </form>
                </Box>
            </Box>
        </Box>
    )
}